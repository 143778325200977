main {
  background-image: url("../../images/default/open-meadow_large.png");
  // background-attachment: scroll;
  background-size: cover;
  background-position: 100% 100%;
}

.main-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 95%;
  margin: 0em auto;
  // background-color: rgba(0, 0, 0, 0.3);

  #project-title {
    margin: 0.5em 0 0 0;
    font-size: 4em;
  }

  .tools {
    display: flex;
    flex-wrap: wrap;
    width: 40%;
    justify-content: space-evenly;
    align-items: center;

    &--single {
      border: 3px solid black;
      padding: 0.4em;
      width: auto;
      border-radius: 1.3em;

      span {
        margin-left: 1em;
      }
    }
  }

  .line {
    &--first {
      width: 90%;
    }

    &--second {
      width: 20em;
    }

    &--third {
      width: 25em;
    }
  }

  .description {
    width: 80%;
    text-align: center;
  }

  .working {
    width: 80%;
    display: flex;
    flex-direction: column;

    &--intro {
      display: flex;

      &--elements {
        margin-right: 2em;
      }
    }

    &--challenges {
      padding: 1em;
      margin: 1em;
      // border: 3px solid rgb(233, 233, 233);
      background-color: rgb(233, 233, 233, 0.6);
      border-radius: 3em;

      &--carousel {
        .carousel-control-prev,
        .carousel-control-next {
          top: 25em;
        }

        &--item {
          display: flex;
          justify-content: center;
          height: 30em;
          width: 100%;
          overflow-y: scroll;

          &--line {
            width: 60%;
          }

          &--text {
            width: 90%;
          }

          &--image {
            width: 50em;
            height: 25em;
            border: 1px solid rgb(194, 194, 194);
            border-radius: 3em;
          }
        }
      }
    }

    &--border {
      display: flex;
      justify-content: space-evenly;
      width: 100em;
      margin: 1em;
      padding: 2em;
      border-radius: 50px;

      &--details {
        #work {
          text-align: left;
        }

        #notes {
          text-align: right;
        }

        #text {
          text-align: left;
        }

        &--right {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          text-align: right;
        }

        &--image {
          box-shadow: 5px 5px 5px;
          border-radius: 50px;
        }
      }
    }
  }
}
#disclaimer-project {
  text-align: center;
  padding: 0.5em;
  margin: 0;
  font-weight: 600;
  color: floralwhite;
  text-shadow: 1px 1px 2px black;
}

.github-link {
  display: flex;
  justify-content: center;
  margin-bottom: 1em;
  text-decoration: none;

  &--separate {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 7em;

    #remove-underline {
      text-decoration: none;
    }
  }
}

@media screen and (max-width: 480px) {
  main {
    background-size: cover;
    // background-repeat: no-repeat;
    background-position: 100%;
  }

  .working {
    // border: 3px solid red;
    width: 100% !important;

    &--intro {
      background-color: rgb(233, 233, 233, 0.6);
      padding: 0.5em;
      border-radius: 2em;
      flex-direction: column-reverse;
      // border: 3px solid red;
    }

    &--challenges {
      overflow: auto;
      // border: 3px solid red;

      &--carousel {
        // width: 10rem;
        // height: 5rem;
        overflow: auto;

        &--item {
          width: 10%;
          height: auto;

          &--image {
            display: none;
            // width: 1px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 480px) and (max-width: 1024px) {
  .working {
    // border: 3px solid red;
    width: 100% !important;

    &--intro {
      background-color: rgb(233, 233, 233, 0.6);
      padding: 0.5em;
      border-radius: 2em;
      flex-direction: column-reverse;
      // border: 3px solid red;
    }

    &--challenges {
      overflow: auto;
      // border: 3px solid red;

      &--carousel {
        // width: 10rem;
        // height: 5rem;
        overflow: auto;

        &--item {
          width: 10%;
          height: auto;

          &--image {
            display: none;
            width: 1px;
          }
        }
      }
    }
  }
}
