.image_carousel {
  // border: 3px solid red;
  width: 100%;
  height: 23em;
  overflow: hidden;

  &--image {
    // border: 3px solid red;
    width: 100%;
    height: 100%;
  }

  .slick-dots {
    // width: 10rem;
    margin: 1.5rem 0;
    // border: 3px solid red;
  }

  .slick-arrow {
    // border: 3px solid red;
    z-index: 1;
    background-color: rgb(0, 0, 0, 0.3);
    border-radius: 10rem;
    width: 5%;
    height: 10%;
  }

  .slick-arrow:hover {
    background-color: rgb(0, 0, 0, 0.6);
  }

  .slick-prev {
    left: 10em;
  }

  .slick-next {
    right: 10em;
  }

}

@media screen and (max-width: 480px) {
  .image_carousel {
    height: 8em;
    border: 1px solid rgb(143, 143, 143);

    .slick-arrow {
      display: none !important;
    }

    .slick-dots {
      display: none !important;
    }
  }
}