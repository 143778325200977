.intro {
  padding: 30em;
  background-image: url("../../images/default/open-meadow(1).png");
  background-size: cover;
  // background-repeat: no-repeat;
  // background-attachment: fixed;
}

@media screen and (max-width: 480px) {
  .intro {
    padding: 8.6em;
  }
}

@media screen and (min-width: 480px) and (max-width: 1024px) {
  .intro {
    padding: 10em;
  }
}
