.line {
  background-color: #b9d531;
  border-radius: 3px solid red;
  height: 15rem;
  z-index: -3;
}

.project--title {
  font-weight: 600;
  text-align: center;
  font-size: 9rem;
  color: floralwhite;
  margin-top: -13rem;
}


#disclaimer {
  text-align: center;
  padding: 0.5em;
  margin: 0;
  font-weight: 600;
}

@media screen and (max-width: 480px) {
  .project--title {
    font-size: 4rem;
  }
}