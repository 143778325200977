.navbar {
  background-color: #b9d531;
  // background-color: rgb(42, 121, 63);
  position: sticky;
  top: 0px;
  text-shadow: none;
  z-index: 3;

  &--brand {
    text-decoration: none;
  }

  &--brand:hover {
    text-decoration: underline;
  }


}
