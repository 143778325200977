::-webkit-scrollbar {
  display: none;
}

.project {
  // border: 3px solid blue;
  background-image: url("../../images/default/open-meadow_invert.png");
  margin: 0 0 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 50rem;
  overflow: hidden;
  // background-color: #b9d531;
  // border: 3px solid red;

  // &--title {
  //   font-weight: 600;
  //   font-size: 10em;
  //   color: floralwhite;
  //   // margin-top: -13rem;
  // }

  &--slider {
    // border: 3px solid green;
    width: 90%;
    margin: 1rem;

    &--item {
      border-radius: 3em;
      background-color: rgba(255, 255, 255, 0.6);
      // border: 3px solid violet;
      margin: 1rem;
      max-width: 90%;
      height: 40rem;
      padding: 1rem;

      &--image-section {
        // border: 3px solid red;
        overflow: hidden;
        height: 18rem;
        border-radius: 2em;

        &--image {
          // max-width: 720px;
          // max-height: 405px;
          width: 100%;
          height: 100%;
        }
      }

      &--details {
        // border: 3px solid green;
        margin: 1rem 0;
        position: relative;
        overflow: scroll;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 50%;

        &--text {
          &--title {
            font-weight: 600;
          }

          text-align: center;
          margin-bottom: 1rem;
          overflow: auto;
          // border: 3px solid red;

          .has-overflow {
            box-shadow: inset 0 -10px 10px -10px rgba(0, 0, 0, 0.1);
          }
        }

        &--buttons {
          display: flex;
          justify-content: space-evenly;
          justify-items: center;
          align-items: center;
          margin: 1rem;
          bottom: 0;
          left: 0;
          right: 0;
          // border: 3px solid greenyellow;
        }
      }
    }
  }

  .slick-arrow {
    // border: 3px solid red;
    background-color: rgb(185, 213, 49);
    border-radius: 5rem;
    width: 15%;
    height: 6%;
    margin: 22.7rem 10rem;
    z-index: +1;
    position: absolute;
  }

  .slick-arrow:hover {
    background-color: rgb(167, 189, 58);
  }

  .slick-prev {
    margin-right: 1rem;
    // border: 3px solid springgreen;
  }

  .slick-dots {
    // position: absolute;
    // border: 3px solid purple;
    margin: -1rem 25rem;
    width: 50%;
  }

  .slick-slide {
    // border: 3px solid orange;
    width: auto;
    overflow: hidden;
  }
}

@media screen and (max-width: 480px) {
  .project {
    .slick-arrow {
      display: none !important;
    }

    .slick-dots {
      // display: none !important;
      margin: 0 22%;
      width: auto;
    }
  }
}

@media screen and (min-width: 480px) and (max-width: 960px) {
  .project {
    .slick-dots {
      position: absolute;
      // border: 3px solid purple;
      margin: -1rem 12rem;
      // border: 3px solid red;
      width: 50%;
    }
  }
}

@media screen and (min-width: 960px) and (max-width: 1024px) {
  .project {
    .slick-dots {
      position: absolute;
      // border: 3px solid purple;
      margin: -1rem 15rem;
      // border: 3px solid red;
      width: 50%;
    }
  }
}
