.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em;

  .main {
    display: flex;

    &--text {
      margin: 0em 5em;
      overflow: hidden;

      .tool {
        display: flex;
        flex-wrap: wrap;

        &--single {
          border: 1px solid rgb(154, 154, 154);
          width: auto;
          margin: 0.5em;
          padding: 0.25em 0.25em;
          border-radius: 1em;

          &--name {
            margin: 0em 0.5em;
          }
        }
      }

      &--links {
        font-size: 1em;
        margin-right: 1rem;
        margin-bottom: 1rem;

        #remove-underline {
          text-decoration: none;
          color: rgb(32, 121, 251);
          margin-left: 1rem;
        }
      }
    }

    &--image-wrapper {
      position: relative;
      width: 100%;

      &--image {
        max-width: 40rem;
        max-height: 40rem;
        margin: 1rem 0;
        align-self: center;
        border-radius: 30em;
        object-fit: cover;
      }

      .hover-image {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        max-width: 50rem;
        max-height: 50rem;
        margin: 1rem 0;
        transition: opacity 0.3s ease-in-out;
      }
    }

    &--image-wrapper:hover .hover-image {
      display: block;
      opacity: 1;
    }
  }
  #email {
    margin-left: 0.5rem;
    text-decoration: none;
  }

  #name {
    font-weight: 600;
  }

  #open-meadow {
    color: rgb(133, 213, 59);
    font-weight: 600;
  }

  #pers-buttons {
    display: flex;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 480px) {
  .about {
    overflow: hidden;

    .main {
      width: 100%;
      flex-direction: column;

      &--text {
        margin: 0;

        &--links {
          font-size: 0.6em;
        }
      }

      &--image-wrapper {
        &--image {
          max-width: 200px;
          max-height: 220px;
          margin-left: 3rem;
        }

        .hover-image {
          max-width: 20rem;
          max-height: 20rem;
          opacity: 0;
          display: none;
        }
      }

      &--image-wrapper:hover .hover-image {
        display: none;
        opacity: 0;
      }
    }
  }
}

@media screen and (min-width: 480px) and (max-width: 960px) {
  .about {
    overflow: hidden;

    .main {
      // border: 3px solid red;
      width: 100%;
      flex-direction: column;

      &--text {
        margin: 0;

        &--links {
          font-size: 1em;
        }
      }

      &--image-wrapper {
        &--image {
          margin-left: 5rem;
          max-width: 30rem;
          max-height: 30rem;
        }

        .hover-image {
          max-width: 20rem;
          max-height: 20rem;
          opacity: 0;
          display: none;
        }
      }

      &--image-wrapper:hover .hover-image {
        display: none;
        opacity: 0;
      }
    }
  }
}
