@import "~bootstrap/scss/bootstrap";
@import url("https://fonts.googleapis.com/css2?family=Prompt:wght@200;300;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Tilt+Neon&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');

body {
  // font-family: "Droid Sans Mono", "monospace", monospace, "Montserrat",
  //   "Tilt Neon", cursive, source-code-pro, Menlo, Monaco, Consolas,
  //   "Courier New", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
  //   "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  //   sans-serif;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: large;
  // color: floralwhite;
  // text-shadow: 1px 1px 2px black;
  text-decoration: none;
  // background-image: url("../../images/default/pexels-gabriel-peter-710263.jpg");
  // background-size: cover;
  // background-repeat: no-repeat;
  // background-attachment: fixed;
  background-color: #f8f8e9;
}
